/* eslint-disable import/extensions */
import {
  BrickElement,
  defineCustomElement,
  type EventListenerObject,
} from '@amedia/brick-template';

import { carouselStyle, sroStyle, twoItemsImageSizes } from './styles';
import { BrickCarouselData, carouselTemplate } from './template';
import {
  isTouchEnabled,
  mapToCarouselType,
  startHandler,
  moveHandler,
  dragState,
} from './utils';
import { debounce } from './debounce';

@defineCustomElement({
  selector: 'brick-carousel',
})
export class BrickCarousel extends BrickElement implements HTMLElement {
  data: BrickCarouselData;
  private _sliderWidth!: number;
  private _minSlidesToShow: number;
  private _btns: NodeListOf<HTMLButtonElement> | null;
  private observer: MutationObserver;
  private mutationTimeout: number | null = null;
  slidesToShow: number;
  gridColWidth: string;
  numberOfSlides: number;
  isScrolling: boolean;
  slider: HTMLUListElement | null;
  section: HTMLElement | null;
  slideWidth!: number;
  uniqueId: string;
  hideBtnStartEnd?: boolean;
  previous!: HTMLButtonElement;
  next!: HTMLButtonElement;

  constructor(data: BrickCarouselData) {
    super();
    this.data = data;
    this.slider = null;
    this.section = null;
    this.sliderWidth = 768;
    this.slidesToShow = 3;
    this.minSlidesToShow = 1;
    this.gridColWidth = '85%';
    this.numberOfSlides = 0;
    this.slideWidth = 0;
    this.isScrolling = false;
    this.uniqueId = this.generateUUID();
    this.hideBtnStartEnd = false;

    //Binding event handlers to make sure they can be properly removed
    this.btnClick = this.btnClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.updateButtonsAfterScroll = this.updateButtonsAfterScroll.bind(this);
    this.observer = new MutationObserver(this.handleMutations.bind(this));
    this.appendChild = this.privateAppendChild.bind(this);
    this._btns = null;
    this._minSlidesToShow = 1;
  }

  async connectedCallback() {
    this.classList.add(carouselStyle());
    this.setData();
    super.connectedCallback();
    this.slider = this.getSlider();
    this.section = this.querySelector('section');
    this.btns = this.querySelectorAll(
      '[data-content-slider-btn]'
    ) as NodeListOf<HTMLButtonElement>;

    this.minSlidesToShow = parseInt(
      this.getAttribute('min-slides-to-show') || '1'
    );

    // Set unique id on certain attributes
    const skipLink = this.querySelector('a[href^="#skip-"]');
    if (skipLink) {
      skipLink.setAttribute('href', `#skip-${this.uniqueId}`);
    }

    if (this.section) {
      this.section.setAttribute(
        'aria-describedby',
        `carousel-title-${this.uniqueId}`
      );
    }

    if (this.btns) {
      this.previous = this.btns[0];
      this.next = this.btns[1];
    }

    const title = this.querySelector('[id^="carousel-title-"]');
    if (title) {
      title.setAttribute('id', `carousel-title-${this.uniqueId}`);
    }

    const skipTarget = this.querySelector('div[id^="skip-"]');
    if (skipTarget) {
      skipTarget.setAttribute('id', `skip-${this.uniqueId}`);
    }
    this.initializeSlider();
  }

  setData() {
    this.data = {
      children: this.querySelectorAll('brick-carousel > *'),
      type: mapToCarouselType(this.getAttribute('type') || 'carousel'),
    };
  }

  privateAppendChild<T extends Node>(element: T): T {
    return super.appendChild(element);
  }

  toggleButtons(eventType?: string) {
    if (!this.slider) return;

    const scrollPosition = Math.ceil(this.slider.scrollLeft);

    // Adjust the threshold for compact type
    const threshold =
      this.data.type === 'compact' ? this.slideWidth / 4 : this.slideWidth / 2;

    // Disable previous button if we're at the start
    const isAtStart = scrollPosition <= threshold;

    // Disable next button if we're at the end
    const isAtEnd =
      scrollPosition >= this.slider.scrollWidth - this.sliderWidth - threshold;

    this.previous.toggleAttribute('disabled', isAtStart);
    this.next.toggleAttribute('disabled', isAtEnd);

    // Remove sro class and return if not at start or end, and hideBtnStartEnd is true
    if (this.hideBtnStartEnd && !isAtStart && !isAtEnd) {
      this.next.classList.remove(sroStyle);
      this.previous.classList.remove(sroStyle);
      return;
    }

    // Hide and set focus if we're at the end and hideBtnStartEnd is true
    if (isAtEnd && this.hideBtnStartEnd && eventType !== 'mouseleave') {
      this.next.classList.add(sroStyle);
      this.previous.focus();
      this.previous.classList.remove(sroStyle);
    }

    // Hide and set focus if we're at the start and hideBtnStartEnd is true
    if (isAtStart && this.hideBtnStartEnd && eventType !== 'mouseleave') {
      this.previous.classList.add(sroStyle);
      this.next.focus();
      this.next.classList.remove(sroStyle);
    }
  }

  calculateSliderProperties = () => {
    const { numberOfSlides, minSlidesToShow, sliderWidth } = this;
    if (this.data.type === 'gallery') {
      this.gridColWidth = '100%';
      this.slidesToShow = 1;
      return;
    }
    if (this.data.type === 'compact') {
      if (sliderWidth > 460) {
        this.gridColWidth = '26%';
      } else {
        this.gridColWidth = '75%';
      }
      return;
    }
    if (numberOfSlides === minSlidesToShow) {
      this.gridColWidth = '1fr';
      this.slidesToShow = this.minSlidesToShow;
      return;
    }
    if (sliderWidth < 460) {
      this.gridColWidth = '85%';
      this.slidesToShow = this.minSlidesToShow;
      return;
    }

    if (sliderWidth <= 768) {
      if (this.numberOfSlides === 2) {
        this.gridColWidth = '1fr';
      } else {
        this.gridColWidth = '45%';
      }
      this.slidesToShow = 2;
      return;
    }

    if (sliderWidth > 768) {
      if (this.numberOfSlides <= 3) {
        this.gridColWidth = '1fr';
        this.slidesToShow = this.numberOfSlides;
      } else {
        this.gridColWidth = '30%';
        this.slidesToShow = 3;
      }
      return;
    }
  };

  updateStyle = (slider: HTMLUListElement) => {
    this.style.setProperty(
      '--b-carousel-contentLength',
      `${this.numberOfSlides}`
    );
    this.style.setProperty(
      '--b-carousel-contentWidth',
      `${this.gridColWidth || '85%'}`
    );
    slider.style.scrollSnapType = 'inline mandatory';
  };

  toggleStyleItems() {
    if (this.numberOfSlides === 2) {
      this.classList.add(twoItemsImageSizes);
    } else {
      this.classList.remove(twoItemsImageSizes);
    }
  }

  get minSlidesToShow() {
    return this._minSlidesToShow || 1;
  }

  set minSlidesToShow(value) {
    if (value) {
      this._minSlidesToShow = value;
    } else {
      this._minSlidesToShow = 1;
    }
  }

  get sliderWidth() {
    return this._sliderWidth;
  }

  set sliderWidth(value) {
    this._sliderWidth = value;
  }

  get btns() {
    return this._btns;
  }

  set btns(value) {
    this._btns = value;
  }

  //Using the brick-element eventListeners property, to avoid having to handle set up and clean up of these event listeners in the connectedCallback and disconnectedCallback
  get eventListeners(): EventListenerObject[] {
    return [
      {
        selector: 'section',
        action: 'keydown',
        listener: this.handleKeydown.bind(this),
      },
      {
        selector: 'window',
        action: 'resize',
        listener: debounce(this.handleResize.bind(this)),
      },
      {
        selector: 'window',
        action: 'orientationchange',
        listener: debounce(this.handleResize.bind(this)),
      },
    ];
  }

  removeNavigationEvents() {
    const { slider } = this;
    if (!slider) return;

    slider.removeEventListener('mousedown', this.handleMouseDown);
    slider.removeEventListener('mouseleave', this.handleMouseLeave);
    slider.removeEventListener('mouseup', this.handleMouseLeave);
    slider.removeEventListener('mousemove', this.handleMouseMove);
  }

  addNavigationEvents() {
    if (!this.slider) return;

    // Click & Drag
    this.slider.addEventListener('mousedown', this.handleMouseDown);
    this.slider.addEventListener('mouseleave', this.handleMouseLeave);
    this.slider.addEventListener('mouseup', this.handleMouseLeave);
    this.slider.addEventListener('mousemove', this.handleMouseMove);

    // Button Clicks
    if (this.btns) {
      this.btns.forEach((btn) => btn.addEventListener('click', this.btnClick));
    }

    // Toggle buttons after scroll has stopped
    this.slider?.addEventListener('scroll', this.updateButtonsAfterScroll);
  }

  handleEvents() {
    this.addNavigationEvents();
  }

  handleMouseDown = (e: MouseEvent) => {
    startHandler(this.slider, e.clientX);
  };

  handleMouseMove = (e: MouseEvent) => {
    moveHandler(this.slider, e.clientX);
  };

  handleMouseLeave = (e: MouseEvent) => {
    dragState.isDown = false;
    if (this.slider) {
      this.slider.classList.remove('grabbing');
      this.slider.style.scrollSnapType = 'none';
      requestAnimationFrame(() => {
        this.toggleButtons(e.type);
      });
    }
  };

  updateButtonsAfterScroll() {
    if (!this.isScrolling) {
      this.isScrolling = true;
      requestAnimationFrame(() => {
        this.toggleButtons();
        this.isScrolling = false;
      });
    }
  }

  skipTarget() {
    return this.querySelector('div[data-target="skip"') as HTMLAnchorElement;
  }

  generateUUID() {
    if (typeof crypto === 'object') {
      if (typeof crypto.randomUUID === 'function') {
        // https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
        return crypto.randomUUID();
      }
      if (
        typeof crypto.getRandomValues === 'function' &&
        typeof Uint8Array === 'function'
      ) {
        // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
        const callback = (c) => {
          const num = Number(c);
          return (
            num ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))
          ).toString(16);
        };
        return '10000000-1000-4000-8000-100000000000'.replace(
          /[018]/g,
          callback
        );
      }
    }
    let timestamp = new Date().getTime();
    let perforNow =
      (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
      0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let random = Math.random() * 16;
      if (timestamp > 0) {
        random = (timestamp + random) % 16 | 0;
        timestamp = Math.floor(timestamp / 16);
      } else {
        random = (perforNow + random) % 16 | 0;
        perforNow = Math.floor(perforNow / 16);
      }
      return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
    });
  }

  handleResize = () => {
    if (!this.slider) return;
    this.updateCarousel();
  };

  private doScroll(scrollLength: number) {
    if (this.isScrolling || !this.slider) return;
    this.slider.classList.remove('grabbing');
    this.isScrolling = true;

    const currentScrollPosition = this.slider.scrollLeft;
    const newScrollPosition = currentScrollPosition + scrollLength;

    this.slider.scrollTo({
      top: 0,
      left: newScrollPosition,
      behavior: 'smooth',
    });

    // Reset isScrolling after the scroll animation is likely to be complete
    setTimeout(() => {
      this.isScrolling = false;
      this.updateButtonsAfterScroll();
    }, 100); // Adjust this timeout as needed
  }

  btnClick = (event: MouseEvent) => {
    const buttonClicked = (event.target as Element).closest(
      'button'
    ) as HTMLButtonElement;
    if (this.slider) {
      this.slider.style.scrollSnapType = 'inline mandatory';
    }
    if (buttonClicked.classList.contains('prev-btn')) {
      // If previous is clicked, then scroll back
      this.doScroll(-this.slideWidth);
    } else if (buttonClicked.classList.contains('next-btn')) {
      // If next is clicked, then scroll forward
      this.doScroll(this.slideWidth);
    }
  };

  getSlider = () =>
    this.querySelector('[data-contents-wrapper]') as HTMLUListElement;

  getSection = () => this.querySelector('.carousel') as HTMLDivElement;

  getSlideContents = () =>
    this.querySelectorAll(
      '[data-content-wrapper]'
    ) as NodeListOf<HTMLLIElement>;

  initializeSlider = () => {
    this.slider = this.getSlider();
    if (!this.slider) return;
    this.observer.observe(this, { childList: true });

    this.observer.observe(this.slider, { childList: true });

    this.addItems();
    this.handleEvents();
    this.setAttribute('initialized', 'true');
  };

  async disconnectedCallback(): Promise<void> {
    super.disconnectedCallback();
    this.removeNavigationEvents();
    this.observer.disconnect();
  }

  static get observedAttributes() {
    return ['hide-btn-start-end', 'initialized'];
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name && oldValue === newValue) return;

    if (name === 'hide-btn-start-end') {
      this.hideBtnStartEnd = this.hasAttribute('hide-btn-start-end');
      if (this.hasAttribute('initialized')) {
        this.toggleButtons();
      }
    }
    if (name === 'initialized') {
      if (this.hasAttribute('hide-btn-start-end')) {
        this.toggleButtons();
      }
    }
  }

  updateCarousel() {
    const measurements = {
      sliderWidth: this.clientWidth || 0,
      numberOfSlides: this.numberOfSlides || 0,
    };

    if (measurements.sliderWidth === 0 || measurements.numberOfSlides === 0) {
      return;
    }

    // Use rAF for the write phase
    requestAnimationFrame(() => {
      this.sliderWidth = measurements.sliderWidth;
      this.calculateSliderProperties();
      this.slideWidth = this.calculateItemWidth();

      if (this.slider) {
        this.updateStyle(this.slider);
        this.toggleStyleItems();
      }

      if (
        (!isTouchEnabled && this.numberOfSlides > this.slidesToShow) ||
        this.data.type === 'gallery'
      ) {
        this.btns?.forEach((btn) => (btn.style.display = 'block'));
        this.classList.add('navigation');
      }
    });
  }

  calculateItemWidth(): number {
    if (!this.slider) return 0;
    const gridColWidthPercentage = parseFloat(this.gridColWidth) / 100;
    return this.sliderWidth * gridColWidthPercentage + 12;
  }

  addItems() {
    if (!this.slider) {
      return;
    }
    const fragment = document.createDocumentFragment();
    const children = this.getFilteredChildren();
    const childCount = children.length;

    if (childCount === 0) return;

    for (let i = 0; i < childCount; i++) {
      const child = children[i];
      const li = this.createListItem(i + 1, childCount);
      li.appendChild(child);
      fragment.appendChild(li);
      const childTemplate = fragment.querySelector('template');

      if (childTemplate) {
        const content = document.importNode(childTemplate.content, true);
        childTemplate.replaceWith(content);
      }
    }

    this.slider.appendChild(fragment);
    this.numberOfSlides = this.slider.children.length;
    this.updateCarousel();
  }

  private getFilteredChildren(): Element[] {
    return Array.from(this.children).filter(
      (child) =>
        child.nodeType === Node.ELEMENT_NODE &&
        child.tagName !== 'STYLE' &&
        !child.hasAttribute('data-static')
    );
  }

  private createListItem(current: number, total: number): HTMLLIElement {
    const li = document.createElement('li');
    li.setAttribute('data-content-wrapper', '');
    li.classList.add('content-wrapper');
    const pCount = document.createElement('p');
    pCount.classList.add(sroStyle);
    const type = this.data.type === 'gallery' ? 'Bilde' : 'Artikkel';
    pCount.textContent = `${type} ${current} av ${total}.`;
    li.appendChild(pCount);
    return li;
  }

  private handleMutationsDebounced = debounce((mutations: MutationRecord[]) => {
    mutations.forEach((mutation) => {
      if (mutation.target === this) {
        this.handleComponentMutations(mutation);
      }

      if (mutation.target === this.slider) {
        this.handleContentWrapperMutations(mutation);
      }
    });
  }, 50);

  handleMutations(mutations: MutationRecord[]): void {
    this.handleMutationsDebounced(mutations);
  }

  handleComponentMutations(mutation: MutationRecord) {
    let elementsAdded = false;
    if (mutation.type === 'childList') {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          elementsAdded = true;
        }
      });
    }

    if (elementsAdded) {
      this.addItems();
    }
  }

  handleContentWrapperMutations(mutation: MutationRecord) {
    if (mutation.type === 'childList') {
      if (mutation.removedNodes.length > 0) {
        this.numberOfSlides = this?.slider?.children.length || 0;
        this.updateCarousel();
      }
    }
  }

  get HTML() {
    return carouselTemplate({
      children: this.querySelectorAll('brick-carousel > *'),
      type: mapToCarouselType(this.getAttribute('type') || 'carousel'),
    });
  }

  handleKeydown(event: KeyboardEvent) {
    const firstFocusable = this.querySelectorAll(
      '[data-content-wrapper]:nth-last-of-type(2) button, [data-content-wrapper]:nth-last-of-type(2) [href], [data-content-wrapper]:nth-last-of-type(2) input, [data-content-wrapper]:nth-last-of-type(2) [tabindex="0"]'
    )[0] as HTMLElement | null;

    switch (event.key) {
      // If left arrow key is pressed, move to previous slide
      case 'ArrowLeft':
        this.doScroll(-this.slideWidth);
        break;
      // If right arrow key is pressed, move to next slide
      case 'ArrowRight':
        this.doScroll(this.slideWidth);
        break;
      case 'Tab':
        // If user has tabbed to the last data-content-wrapper, hide the next button
        if (!this.btns) return;
        this.next?.classList.add('hidden');
        if (firstFocusable === document.activeElement) {
          this.next.disabled = true;
        }
        break;
      // Escape to skip out of the carousel
      case 'Escape':
        this.skipTarget().focus();
        break;
      default:
        return;
    }
  }
}
